@import "scss/style";

// Surcharge bootstrap

.table-responsive {
  overflow-x: visible;
}

button:focus {
  outline: none;
}

input::-webkit-calendar-picker-indicator:focus {
  outline-style: none;
  border-style: solid;
  border-radius: 3px;
  border-width: 1px;
  border-color: lighten($primary-color, 40%);
}

.text-red {
  color: $red;
}

.text-blue {
  color: $blue;
}

//.explication-step {
//  color: black;
//  font-weight: bold;
//}

.custom-select {
  font-size: 0.9rem !important;
  padding: .375rem 1.5rem .3rem .5rem !important;
}

input[readonly], input[disabled], textarea[readonly], textarea[disabled], p.form-control {
  border: none !important;
  padding: 0 !important;
  background-color: transparent !important;
}

input[readonly]:focus {
  border: none !important;
  box-shadow: none;
  outline: 0 none;
}

.ascore-disabled {
  background-color: #e9ecef !important;
  padding: .3rem .6rem !important;
  border: 1px solid #ced4da !important;
  line-height: 1rem !important;
}

ul {
  padding: 0 !important;
  margin: 0 !important;
}

.bt-menu-trigger span, .bt-menu-trigger span::after, .bt-menu-trigger span::before {
  background-color: #ff721e !important;
}

.input-upper {
  text-transform: uppercase;
}

.tab-content > .active {
  display: flex !important;
  flex-direction: column !important;
  flex: 1;
}

.dropdown-menu {
  padding: 0 !important;
}

input.ng-invalid:not(.ng-pristine) {
  border: solid 1px red;
}

textarea.ng-invalid:not(.ng-pristine) {
  border: solid 1px red;
}

html, body, ascore-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
}

legend {
  width: inherit !important;
  padding: 0 10px !important;;
  border-bottom: none !important;;
  font-size: 1.2rem !important;
}

fieldset {
  border: 1px solid #bfc7c6 !important;
  padding: 0 1.4em 0 1.4em !important;
  margin: 1rem 0 0 0 !important;
  border-radius: 5px !important;;
}

.ascore-content-container {
  flex-flow: column;
}

.ascore-content-container > * {
  flex: 1;
}

// Gestion global du style pour les selects

ngb-typeahead-window {
  min-width: 100% !important;
}

.invisible-options ngb-typeahead-window {
  display: none !important;
}

ngb-typeahead-window {
  overflow-y: auto;
  max-height: 300px;
}

.label-ascore {
  color: #1f6b77 !important;
  font-weight: bold;
}

.label-ascore-search {
  color: #1f6b77 !important;
  font-weight: bold;
  font-size: 11px;
  margin-top: 0 !important;
  position: absolute;
  justify-content: left;
}

.label-required {
  color: $red !important;
  font-weight: bold;
}

.control-error-inline-tooltip .tooltip-inner {
  color: white;
  background-color: $red;
}

.control-error-inline-tooltip .arrow::before {
  border-top-color: $red;
}

.label-ascore.inline {
  display: block;
  text-align: right;
}

// Pour les select en mode inline
fa-icon.inline {
  top: 5px !important;
}

.toast-header {
  color: inherit !important;
  background-color: inherit !important;

  button {
    color: inherit !important;
  }
}

.flex-1 {
  flex: 1;
}

.font-weight-bold input {
  font-weight: bold;
}

.form-inline label {
  margin: auto;
  display: flex;
  align-items: normal !important;
}

input[type=number].no-spinner::-webkit-inner-spin-button,
input[type=number].no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input.montant-numeraire-left {
  text-align: left !important;
}

li > ngb-typeahead-window {
  display: contents !important;
}

.modal-full-screen {
  .modal-dialog {
    max-width: 100% !important;
    max-height: 100% !important;
    min-height: 100% !important;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
  }

  .modal-content {
    flex: 1;
  }
}

.pro-body {
  list-style: none;
}

//
//// Gestion spécifique pour la tablette
//
//@media screen and (max-width: 1024px) {
//  .label-ascore {
//    font-size: larger;
//  }
//  .form-control {
//    font-size: larger;
//  }
//  .nav-link {
//    font-size: larger;
//  }
//  .btn {
//    font-size: larger;
//  }
//  .nav-container {
//    padding-right: 10px;
//    padding-left: 10px;
//  }
//  .modal-header, .modal-body {
//    padding: 5px;
//  }
//  .modal-header h6 {
//    font-size: large;
//    flex: 1;
//  }
//  .table-container {
//    font-size: larger;
//  }
//  button[role=option] {
//    font-size: larger;
//  }
//  .icon-select {
//    font-size: large;
//  }
//  .card {
//    box-shadow: none;
//  }
//  input[type=checkbox] {
//    transform: scale(1.5);
//  }
//}

.h-100 > .ng-trigger {
  height: 100%;
}

// pour surcharge du style  de la directive ascore-input

.ascore-input {
  position: relative;
}

.ascore-input.pb-0 {
  padding-bottom: 0 !important;
}

.ascore-input.p-0 {
  padding: 0 !important;
}

input[readonly] {
  font-size: 13px;
}

input[type="date"] {
  //max-width: 150px;
  //min-width: 150px;
}

.pcoded-main-container {
  height: 100vh !important;
}

// Pour griser le fond d'une modal ouverte depuis une autre modal
.backdrop-over-modal {
  z-index: 1072 !important;
}

.template-inside-table {
  padding: 0.4rem 0.75rem !important;
}

.modal-header {
  background-color: #375EA3;
  border-color: #375ea373;
}

// pour faire ressortir un peu plus le texte sur fond vert
.modal-header h6 {
  color: #000000;
}

//.diff-caracteritiques-green {
//  background-color: #dffbe2;
//  border: solid 1px white;
//  border-radius: 10px;
//  padding-left: 5px;
//}

.ascore-pagination {
  background-color: white;
}

.add-button, .action-button {
  background-color: #31859c;
  border-color: #31859c;
  color: white;
}

.add-button, .action-button {
  background-color: #31859c !important;
  border-color: #31859c !important;

  &:hover {
    background-color: #27697B !important;
    border-color: #31859c !important;
  }
}

.btn {
  box-shadow: 1px 1px 2px black;
}

.nav-link.active {
  background-color: #375EA3 !important;
  opacity: 0.9;
  color: white !important;
  font-weight: bold;
}

fieldset {
  padding-bottom: 10px !important;
}

legend {
  color: #31859c;
  margin-bottom: 0.1rem;
}

.filedrop {
  height: 170px;
}

.drop-zone {
  border: 2px dotted #0782d0;
  border-radius: 30px;
  height: 170px;
  margin: auto;
}

.modal-footer {
  justify-content: center !important;
}

.modal-title {
  color: white !important;
  width: 100%;
  font-weight: bold;
  text-align: center;
  font-size: 24px;
}

.modal-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  min-height: calc(100vh - 60px);
}

@media (max-width: 767px) {
  .modal-dialog {
    min-height: calc(100vh - 20px);
  }
}

td * {
  box-shadow: none !important;
}

.card-header h4 {
  color: #31859c !important;
}

.modal-body {
  background-color: whitesmoke; // $default-background-color;
}

.modal-xxl {
  max-width: 1600px;
}

.tooltip-inner {
  background-color: black;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
  border-top-color: black !important;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
  border-right-color: black !important;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
  border-left-color: black !important;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
  border-bottom-color: black !important;
}

th, td {
  text-align: left;
}

.card .card-header {
  border-bottom: 1px solid lightgrey;
}

.nav-tabs {
  border-bottom: 1px solid lightgrey;
}

.ascore-footer {
  position: fixed;
  bottom: 0;
  right: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  margin-left: -15px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-top: solid 1px;
  border-color: #8CDDCD;
  z-index: 100;
}


@media print {
  div {
    break-inside: avoid;
  }
  .navbar {
    display: none;
  }
  .pcoded-navbar {
    display: none;
  }
  .pcoded-main-container {
    margin: 0;
    padding: 0 !important;
  }
}

.icon-search:before {
  display: none;
}
.icon-grid:before {
  display: none;
}


.modal-content {
  border-radius: 2em;
}

.modal-header {
  border-top-right-radius: 1.5em;
  border-top-left-radius: 1.5em;
}

.fake-pie-grid {
}

.fake-pie-grid text[ngx-charts-count-up]:last-child {
  display: none;
}

.visually-hidden{
  display: none;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.d-flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}
